import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class EmployeeService{
    getEmployeeLists(param){
        const url = `api/employee/filter`
        const params = {
            'page-index': param.size,
            'searched': param.searched,
            'branch':param.branch,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeElements(){
        const url = `api/employee/create`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getEmployeeProfileData(id){
        const url = `api/employee/show/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getBranchAttributes(){
        const url = 'api/employee/create'
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getPayrollLists(param){
        const url = `api/salary-payroll/filter`
        const params ={
            'page-index':param.pageIndex,
            'employee':param.employee,
            'branch':param.branch,
            'today': param.today,
            'month':param.month,
            'year':param.year,
            'from':param.from_date,
            'to':param.to_date,
            'searched':param.searched,
            'page-reload':param.page_reload,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    createNewemployee(formData){
        const url = `api/employee/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    editEmployee(formData,id){
        const url = `api/employee/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    deleteEmployee(id){
        const url = `api/employee/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
    createNewPayroll(formData){
        const url = `api/salary-payroll/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    getPayrollElements(){
        const url = `api/salary-payroll/create`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getReceiptNoByBranch(branch){
        const url = `api/salary-payroll/receipt-no/${branch}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    cancelPayroll(id,remarks){
        const url = `api/salary-payroll/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,remarks,headers);
    }
    getAttendenceData(param){
        const url = `api/attendence/filter`
        const params ={
            'branch':param.branch,
            'date':param.date,
            'searched':param.searched,
            'page-reload':param.page_reload,
            'page-index':param.pageIndex,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    submitAttendence(formData){
        const url = `api/attendence/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    getMonthlyAttendence(param){
        const url = `api/attendence/month/report/filter`
        const params ={
            'branch':param.branch,
            'page-index':param.pageIndex,
            'month':param.month+1,
            'year':param.year,
            'searched':param.searched,
            'page-reload':param.page_reload,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getMonthlyIndividualReport(param){
        const url = `api/attendence/individual/report`
        const params ={
            'month':param.month,
            'year':param.year,
            'id':param.id,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getSalaryMasterEmployeeAttendence(param){
        const url = `api/salary-master/employee/month/attendence`
        const params ={
            'month':param.month,
            'year':param.year,
            'id':param.id,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getSalaryMasterList(param){
        const url = `api/salary-master/filter`
        const params ={
            'branch':param.branch,
            'page-index':param.pageIndex,
            'month':param.month+1,
            'year':param.year,
            'searched':param.searched,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    updateSalaryMaster(formdata,id){
        const url = `api/salary-master/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    createSalaryMaster(formdata){
        const url = `api/salary-master/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    getAttendenceDayReport(param){
        const url = `api/attendence/day/report/filter`
        const params ={
            'branch':param.branch,
            'page-index':param.pageIndex,
            'date':param.date,
            'searched':param.searched,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeePayrollList(param){
        const url = `api/employee/individual/payroll`
        const params = {
            'id':param.id,
            'page-index': param.pageIndex,
            'searched': param.searched,
            'from':param.from,
            'to':param.to,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeLedgerList(param){
        const url = `api/employee/ledger`
        const params = {
            'id':param.id,
            'page-index': param.pageIndex,
            'searched': param.searched,
            'from':param.from,
            'to':param.to,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeLedgerFilter(param){
        const url = `api/employee/ledger/filter`
        const params = {
            'id':param.id,
            'page-index': param.pageIndex,
            'searched': param.searched,
            'from':param.from,
            'to':param.to,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeAttendenceList(param)
    {
        const url = `api/employee/attendence/yearly`
        const params = {
            'id':param.id,
            'page-index': param.pageIndex,
            'searched': param.searched,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getSalaryMasterExtraFields(){
        const url = `api/settings/employee/salary-master`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getSalaryMasterTaxExtraFields(){
        const url = `api/salary-master/tax/extra-fields`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    //employeeSettings
    createEmployeeSetting(formData){
        const url = `api/settings/employee`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    updateEmployeeSetting(formData,id){
        const url = `api/settings/employee/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    createNewExtraField(formData){
        const url = `api/settings/employee/salary-master`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    editNewExtraField(formData,id){
        const url = `api/settings/employee/salary-master/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    deleteEmployeeExtraField(id){
        
        const url = `api/settings/employee/salary-master/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new EmployeeService();