<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='receiptSlip' ||modalId == 'profilePayrollSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content bg-white border0">
           <div class="cancel-watermark" v-if="(details.status == 1)">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">Salary Payment Slip - #{{details.receipt_no}}</h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" title="Print" @click="print()"></i>
                </div>
                <div class="bill-head-icon mr-3" title="Download">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  title="Close"
                  @click="$store.dispatch('modalClose','receiptSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
               <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
              <p class="mb-0">
                {{details.branch.name}}
                <br />Tel No:{{authData.org_detail.phone}} / {{authData.org_detail.email}}
                <br />PAN:{{authData.org_detail.pan_vat}}
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-4 col-lg-4 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Employee</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">ID : {{details.employee.employee_id}}</p>
                <p class="mg-b-0">NAME : {{details.employee.name}}</p>
                
              </div>

               <div class="col-sm-4 col-lg-4 order-1 order-sm-0">
                <p class="text-center font-l m-0"> Payroll Receipt <span v-if="details.is_advance ==1" class="tx-primary">/ Advance</span></p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">Receipt No. : #{{details.receipt_no}}</p>
                <p class="mg-b-0 text-right">DATE : {{details.date}}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div class="col-sm-12 col-lg-12 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{details.payment_method.title}}</td>
                        <td>{{details.txn_id}}</td>
                        <td>{{details.paid_amt}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
            </div>
            <div class="row justify-content-between mt-1 font-s font-w-400">
              
              <div class="col-sm-0 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <!-- Note -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Amount in Word</label>
                  <p class="mb-0">
                    {{toWord(details.paid_amt)}} Only
                  </p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0">
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Due Amount</span>
                    <span>{{details.due_amt}}</span>
                  </li>
                  </ul>
              </div>
            </div>
            
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{details.description}}
              </p>
            </div>
            <p class="text-right">User: {{details.user_details.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        details: [],
      };
    },
    computed: {
      ...mapGetters(["dataLists", "dataId", "authData","modalId", "modalMode"]),
      ...mapGetters("employees",["payrollLists","payrollElements","employeePayroll"])
    },
    methods:{
      print(){
        window.print();
      }
    },
    watch: {
      modalId(value) {
        if(value == 'receiptSlip'){
          let id = this.dataId;
          this.details = this.payrollLists.find(function (data) {
            return data.id == id;
          });
        }
        if(value == 'profilePayrollSlip'){
          let id = this.dataId;
          this.details = this.employeePayroll.find(function (data) {
            return data.id == id;
          }); 
        }
      },
    },
  };
</script>